/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.backButton {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  cursor: pointer;
  margin: 0;
  font-weight: 700;
  user-select: none;
  border: 1px solid transparent;
  transition-duration: .2s;
  overflow: visible;
  border-color: #676d73;
  transition-property: background-color,color,opacity;
  color: #676d73;
  background-color: #fff;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 16px;
  border-radius: 4px;
  line-height: 24px;
  padding: 11px 32px;
}

.nextButton {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  cursor: pointer;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  padding: 8px 24px;
  overflow: visible;
  -webkit-transition-property: background-color,color,opacity;
  transition-property: background-color,color,opacity;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  background-color: #131e35;
  border: 1px solid transparent;
  color: #fff;

  font-size: 16px;
    border-radius: 4px;
    line-height: 24px;
    padding: 11px 32px;

    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.descField {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 20px;
  font-family: Avenir;
  outline: none;
}

.emailField {
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 20px;
font-family: Avenir;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 20px;
  font-family: Avenir;
}

.signUpContainer {
  position: fixed;
  top: -1px;
  z-Index: 200;
  color: #fafafa;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUpBox {
  position: relative;
    width: 50%;
    height: 400px;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 30px;
}

.bottomRow {
  border-top: 1px solid rgb(216, 216, 216);
  position: absolute;
  bottom: 1em;
  width: 92%;
  padding-top: 20px;
}

@media only screen and (max-width: 600px) {
  .signUpContainer {
    position: fixed;
    top: -1px;
    z-Index: 200;
    color: #fafafa;
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .signUpBox {
    position: relative;
      width: 100%;
      height: 400px;
      color: rgb(0, 0, 0);
      background-color: rgb(255, 255, 255);
      border-radius: 4px;
      padding: 30px;
  }

  .bottomRow {
    border-top: 1px solid rgb(216, 216, 216);
    position: absolute;
    bottom: 1em;
    width: 80%;
    padding-top: 20px;
  }
}
::placeholder { /* Most modern browsers support this now. */
	color:    #c3c3c3de;
  font-family: "Avenir Next";
  font-size: 10px;
}


/**Desktop Query*/
@media only screen and (min-width: 768px) {
  .dashCard {
      display: inline;
      float: left;
      margin-right: 30px;
      width: 30%;
      min-width: 300px
  }
  .dashContainer {
      margin-left: 15%;
      margin-right: 15%;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width:768px) {
  .dashCard {
      display: inline;
      float: left;
      width: 100%;
  }
  .dashContainer {
      margin-left: 15%;
      margin-right: 15%;
  }
}

/*Mobile Query*/
@media only screen and (max-width:480px) {
  .dashCard {
      display: inline;
      float: left;
      width: 100%;
  }
  .dashContainer {
      margin-left: 5%;
      margin-right: 5%;
  }
}